// supabaseClient.js
import { createClient } from '@supabase/supabase-js'

const SUPABASE_URL_Local = 'http://192.168.10.50:54321' // replace with your local Supabase URL
const SUPABASE_ANON_KEY_Local = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZS1kZW1vIiwicm9sZSI6ImFub24iLCJleHAiOjE5ODM4MTI5OTZ9.CRXP1A7WOeoJeXxjNni43kdQwgnWNReilDMblYTn_I0' // replace with your correct Supabase anon key

const SUPABASE_URL = 'https://rzubbjtwdlrdlgvaelfa.supabase.co'
const SUPABASE_ANON_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJ6dWJianR3ZGxyZGxndmFlbGZhIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTkwNjc5MzAsImV4cCI6MjAzNDY0MzkzMH0.CfYFf4WM5BTtP_z6Uoe0Nf_o0t495bRzMsGgjgJKa4Q'

const supabase = createClient(SUPABASE_URL, SUPABASE_ANON_KEY)

export default supabase
