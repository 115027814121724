import "./App.css";
import {
  Container,
  createMuiTheme,
  Grid,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core";
import Header from "./components/Header";
import Footer from "./components/Footer";
import HeaderBlock from "./components/HeaderBlock";
import AppList from "./components/AppList";
import TypeClubList from "./components/TypeClubList";






const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: theme.spacing(3),
  },
}));

function App() {
  

  const darkTheme = createMuiTheme({
    palette: {
      type: "dark",
    },
  });

  const blockId = 2; // Replace with the desired block ID

  const classes = useStyles();

  return (
    <ThemeProvider theme={darkTheme}>
      <Container>
        <Header />
        <HeaderBlock blockId={blockId} />
        <AppList />
        <TypeClubList />





      </Container>
      <Footer
        title="Footer"
        description="Something here to give the footer a purpose!"
      />
    </ThemeProvider>
  );
}

export default App;