
import supabase from './supabaseClient';
import React, { useEffect, useState } from 'react';
import TypeClub from './TypeClub';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    input: {
      outline: 0,
      color: '#333333',
      fontSize: '22px',
      padding: '11px 10px',
      width: '309px',
      borderRadius: '14px',
      border: '1px solid #d6d6d6',
      backgroundColor: '#F5F5F5FF',
      boxShadow: '2px 2px 0px #dbd0d0',
      '&:active, &:focus': {
        backgroundColor: '#ffffff',
        color: '#000000',
        border: '1px solid #969696',
      },
    },
  });

const TypeClubList = () => {
    const [typeClubs, setTypeClubs] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const classes = useStyles();

    useEffect(() => {
        const fetchTypeClubs = async () => {
            let query = supabase
                .from('typeclubs')
                .select('*')
                .order('typeclubname', { ascending: true });
                
            if (searchQuery) {
                query = query.ilike('typeclubname', `%${searchQuery}%`);
            }

            const { data, error } = await query;
            if (error) console.error('Error fetching Type Clubs:', error);
            else setTypeClubs(data);
        };

        fetchTypeClubs();
    }, [searchQuery]);

    return (
        <>
            <div><h1><center>Type Clubs</center></h1></div>
            <div><h1><center>
                
                <input 
                    className={classes.input}
                    type="text"
                    placeholder="Search by Type Club name"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    ></input>
                </center></h1>
            </div>

            <div className="app-container">
                {typeClubs.map(typeClub => (
                    <TypeClub key={typeClub.id} typeClub={typeClub} />
                ))}
            </div>
        </>
    )
}

export default TypeClubList;