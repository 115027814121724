import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardMedia, Box } from '@mui/material';
import { Badge, Divider, Icon, IconButton, Toolbar, Typography, makeStyles } from "@material-ui/core";
import supabase from "./supabaseClient";

// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        color: 'white',
        textAlign: 'center', // Center content horizontally
        padding: theme.spacing(3), // Add padding to the card
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '300px', // Set the card height to 400px
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative', // Ensure the card is positioned relatively for absolute positioning of subtitle
        [theme.breakpoints.down('sm')]: { // Styles for small screens
            padding: theme.spacing(2),
            height: '300px'
        }
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center', // Center content horizontally
        width: '100%',
        height: '100%'
    },
    title: {
        fontSize: '80px', // Adjust font size for title
        color: 'white',
        textShadow: '2px 2px #000000',
        textAlign: 'center', // Center content horizontally
        justifyContent: 'center',
        marginBottom: theme.spacing(1), // Add bottom margin to title
        fontFamily: 'Arial', // Example of setting font family
        fontWeight: 'bold',
        backgroundColor: 'rgb(0,0,0, 30%)',
        [theme.breakpoints.down('sm')]: { // Styles for small screens
            fontSize: '2rem'
        }
    },
    subtitle: {
        fontSize: '25px', // Adjust font size for subtitle
        color: 'white',
        textShadow: '1.5px 1.5px #000000',
        fontStyle: 'italic', // Apply italic style to subtitle
        position: 'absolute', // Position the subtitle absolutely
        bottom: theme.spacing(2), // Distance from the bottom of the card
        [theme.breakpoints.down('sm')]: { // Styles for small screens
            fontSize: '1.2rem',
            bottom: theme.spacing(0.5)
        }
    }
}));

const fetchBlockData = async (blockId) => {
    if (!blockId) {
        console.error('blockId is undefined');
        return null;
    }
    const { data, error } = await supabase
        .from('blocks')
        .select('title, subtitle, image')
        .eq('id', blockId)
        .single(); // Fetch the record with the given ID

    if (error) {
        console.error('Error fetching data:', error);
        return null;
    }
    return data;
};

const HeaderBlock = ({ blockId }) => {
    const classes = useStyles();
    const [blockData, setBlockData] = useState(null);

    useEffect(() => {
        if (blockId) {
            const getBlockData = async () => {
                const data = await fetchBlockData(blockId);
                if (data) {
                    setBlockData(data);
                }
            };
            getBlockData();
        } else {
            console.error('blockId is undefined in HeaderBlock component');
        }
    }, [blockId]);

    if (!blockData) {
        return <div>Loading...</div>;
    }

    return (
        <Card
            className={classes.root}
            sx={{
                backgroundImage: blockData.image ? `url(/images/${blockData.image})` : 'none'
            }}
        >
            <CardContent className={classes.content}>
                <Typography className={classes.title} component="div">
                    {blockData.title}
                </Typography>
                <Typography className={classes.subtitle} component="div">
                    {blockData.subtitle}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default HeaderBlock;
