import { Badge, Divider, Icon, IconButton, Toolbar, Typography, makeStyles } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsIcon from "@material-ui/icons/Notifications";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SideDrawer from "./SideDrawer";
import { Router, Route, Routes, Link } from 'react-router-dom';



const useStyles = makeStyles((theme) => ({
    title1: {
        fontSize: 40,
        color: 'cyan',
    },
    title2: {
        fontSize: 40,
        color: 'red',
    },
    tagline: {
        fontSize: 20,
        textTransform: "uppercase",
        justifyContent: "center",
        fontFamily: "Verdana"
    },
    icons: {
        position: 'absolute',
        right: '5px',
    }
}));


const Header = () => {
    const classes = useStyles();
    return (
        <>
            <Toolbar>
                <SideDrawer>
                <IconButton color="inherit">
                    <MenuIcon />
                </IconButton>
                </SideDrawer>

                
                    <Typography variant="h4" className={classes.title1} display="inline">Aero</Typography>
                    <Typography variant="h4" className={classes.title2} display="inline">Hub</Typography>
                    <Typography variant="h4" className={classes.title1} display="inline">.ai</Typography>

            
            <div className={classes.icons}>
                
                <IconButton color="inherit">
                    <Badge badgeContent={4} color="secondary">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>

                <IconButton color="inherit">
                    <AccountCircleIcon />
                </IconButton>
                </div>
            </Toolbar>

            <Divider />

           
        </>
    );
};
  
export default Header;