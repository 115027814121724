import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '250px',
    height: '300px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #ddd',
    borderRadius: '20px',
    overflow: 'hidden',
    cursor: 'pointer',
    transition: 'transform 0.2s',
    backgroundColor: '#280248',
    background: 'linear-gradient(#ffffff, #014715)',
  },

  image: {
    maxWidth: '260px',
    maxHeight: '200px',
    objectFit: 'contain',
    backgroundColor: '#eeeeee',
    //height: '128px',
  },

  text: {
    fontSize: '25px',
    textAlign: 'center',
    margin: '20px 0',
    fontWeight: 'bold',
  }



}))

const TypeClub = ({ typeClub }) => {
  const classes = useStyles();

  const handleClick = () => {
    //window.location.href = typeClub.url;
    window.open(typeClub.url, '_blank');
  };

  return (
    <div className={classes.card} onClick={handleClick}>
      <img src={typeClub.logo} alt={typeClub.typeclubname} className={classes.image} />
      <p className={classes.text}>{typeClub.typeclubname}</p>

    </div>
  );
};

export default TypeClub;
