import { Container, Grid, Typography, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'

const useStyles = makeStyles((theme) => ({
    footer: {
      backgroundColor: theme.palette.background.paper,
      marginTop: theme.spacing(4),
      padding: theme.spacing(6, 0),
    },
  }));

const Footer = ({ title, description }) => {
    const [year, setYear] = useState(new Date().getFullYear()); //get current year for copyright
    const classes = useStyles();
    return (
        <footer className={classes.footer}>

        
        <Container maxWidth='lg'>

                <Typography variant='h6' align="center" gutterBottom>
                    {title}
                </Typography>
                <Typography variant='subtitle1' color='textSecondary' align='center' component='p'>
                    {description}
                </Typography>
                <Typography variant='body2' color='textSecondary' align='center' >
                    Copyright © {year} {"AeroHub.ai"}. All rights reserved.
                </Typography>

        </Container>

        </footer>

    )
}

export default Footer