
import supabase from './supabaseClient';
import React, { useEffect, useState } from 'react';
import AppCard
    from './AppCard';

const AppList = () => {
    const [apps, setApps] = useState([]);

    useEffect(() => {
        const fetchApps = async () => {
            const { data, error } = await supabase
                .from('apps')
                .select('*')
                .order('appname', { ascending: true });
            if (error) console.error('Error fetching apps:', error);
            else setApps(data);
        };

        fetchApps();
    }, []);

    return (
        <>
            <div><h1><center>Applications</center></h1></div>

            <div className="app-container">
                {apps.map(app => (
                    <AppCard key={app.appid} app={app} />
                ))}
            </div>
        </>
    )
}

export default AppList